'use client';

import { cn } from '@inderes/videosync-ui';

interface Props {
  error: Error;
}

const Error = ({ error }: Props) => {
  return (
    <div className={cn('text-destructive flex h-full w-full flex-col items-center justify-center text-2xl')}>
      <p>Oopsie daisies!</p>
      <p>{error.message}</p>
    </div>
  );
};

export default Error;
